import { useState, useEffect } from 'react';

//interfaces
import { IContract } from '../../../interfaces/IContract';
import { IIncomeReport } from '../../../interfaces/IIncomeReport';

//css
import styles from './Income.module.css';

//components
import ViewPdf from '../../../components/viewPdf/ViewPdf';
import Loading from '../../../components/loading/Loading';

//services
import { BankSlipService } from '../../../services/bankSlip/BankSlipService';

//utils
import { Toast } from '../../../utils/Toast';

//api
import { ApiException } from '../../../api/ApiException';
import CustomYearSelector from '../../../components/yearSelector/CustomYearSelector';
import Error from '../../../components/error/Error';
import { File } from '../../../utils/File';
import { FaFileDownload } from 'react-icons/fa';
import { MdOutlineMailOutline } from 'react-icons/md';

type Props = {
    id: string | undefined;
    contract: IContract | null;
};

const Income: React.FC<Props> = ({ id, contract }) => {
    const [data, setData] = useState<IIncomeReport>();
    const [selectedYear, setSelectedYear] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [yearsIncomeReport, setYearsIncomeReport] = useState<number[]>([]);
    const [hasError, setHasError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>('');


    const downloadPDF = () => {
        if (data && data?.base64 && data?.fileName) {
            File.downloadPdf(data?.base64, data?.fileName);
        }
    }

    const sendEmail = async () => {
        setLoading(true);
        BankSlipService.sendEmailIncomeReport(contract?.contractCode || '', String(selectedYear), contract?.dataOrigin || '').then((response) => {
            if (response instanceof ApiException) {
                Toast.error(response.message)
                setLoading(false)
            } else {
                Toast.success("Enviado com sucesso!");
                setLoading(false)
            }
        })
    }

    const getAllYearsIncomeReport = async () => {
        setLoading(true)
        BankSlipService.getAllYearsIncomeReport(contract?.contractCode || '', contract?.dataOrigin || '').then((response) => {
            if (response instanceof ApiException) {
                console.log(response)
                setLoading(false)
                if (response.message.includes("Dados não encontrados")) {
                    const message: string = "Não foram encontrados informes de rendimento para esse contrato";
                    Toast.error(message);
                    setMessageError(message);
                } else {
                    setHasError(true)
                    setMessageError(response.message);
                }
            } else {
                setYearsIncomeReport(response);
                setHasError(false)
                setLoading(false)
            }
        })
    }
    const getDownloadIncomeReport = async () => {
        setLoading(true)
        BankSlipService.getDownloadIncomeReport(contract?.contractCode || '', String(selectedYear), contract?.dataOrigin || '').then((response) => {
            if (response instanceof ApiException) {
                setHasError(true)
                setMessageError(response.message);
                setLoading(false)
            } else {
                setData(response);
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        getAllYearsIncomeReport();
    }, [])

    useEffect(() => {
        if (selectedYear) {
            getDownloadIncomeReport()
        }
    }, [selectedYear]);



    return (
        <>
            <div className={styles.container}>
                {loading && <Loading />}
                <div className={styles.top}>
                    <h2 className={styles.tittle}>Informe de rendimentos</h2>
                    <div className={styles.informations}>
                        <label className={styles.information}>
                            Contrato
                            <strong style={{ color: "#006930" }}>{contract?.contractCode}</strong>
                        </label>
                    </div>
                </div>
                <hr />
                <div className={styles.filter}>
                    {yearsIncomeReport.length > 0 && (
                        <CustomYearSelector
                            years={yearsIncomeReport}
                            setSelectedYearChange={setSelectedYear} />
                    )}
                    {data && (
                        <div className={styles.actionButtons}>

                            {data.base64 && data.fileName && <button className={styles.download} onClick={downloadPDF}><FaFileDownload />Baixar</button>}
                            {sendEmail && <button className={styles.sendEmail} onClick={sendEmail}><MdOutlineMailOutline />Enviar para o E-mail</button>}
                        </div>

                    )}
                </div>
                {data && (
                    <div>
                        <br />
                        <div style={{ width: "100%", paddingBottom: "56.25%", position: "relative" }}>
                            <ViewPdf
                                base={data.base64}
                                fileName={data.fileName}
                            />
                        </div>
                    </div>
                )}
                {!hasError && messageError && (<>{messageError}</>)}
                {hasError && <Error message={messageError} />}
            </div >

        </>
    );
};

export default Income;
