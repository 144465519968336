//react
import React, { useEffect, useState } from 'react';

//datepicker
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

//css
import styles from './DatePickerComponent.module.css';

registerLocale('pt-BR', ptBR);

type Props = {
    selectedDate: Date | null
    setSelectedDate(date: Date | null): void
    initial: number
    final: number

}
const DatePickerComponent: React.FC<Props> = ({ selectedDate, setSelectedDate, initial, final }) => {

    const minDate = addDays(new Date(), initial);
    const maxDate = addDays(new Date(), final);

    useEffect(() => { setSelectedDate(minDate) }, [])

    return (
        <label className={styles.datePickerLabel}>
            Selecione uma data
            <DatePicker
                selected={selectedDate}
                onChange={(date: Date | null) => setSelectedDate(date)}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
            />
        </label>
    );
};

export default DatePickerComponent;
