//css
import styles from "./Login.module.css";

type Props = {
    Logo: string
    SmallLogo: string
}
const SeeLeftInformation: React.FC<Props> = ({ Logo, SmallLogo }) => {
    return (
        <>
            <img className={styles.logoImg} src={Logo} alt="logo BBC" />
            <div className={styles.box}>
                <label>Aqui você pode:</label>
                <div className={styles.sub_box}>
                    <div className={styles.inter_box}>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Atualizar seu cadastro</span>
                        </div>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Solicitar a 2º via de boleto</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SeeLeftInformation