
//react
import { useEffect, useState } from 'react';

//components
import Error from '../../../components/error/Error';
import ViewPdf from '../../../components/viewPdf/ViewPdf';
import Loading from '../../../components/loading/Loading';

//interfaces
import { IContract } from '../../../interfaces/IContract';
import { IGenericFileInfo } from '../../../interfaces/IIncomeReport';
import { IContractSimplified } from '../../../interfaces/IContractSimplified';

//css
import styles from './ContractDoc.module.css';

//icons
import { MdOutlineMailOutline } from 'react-icons/md';
import { FaFileDownload } from 'react-icons/fa';

//utils
import { Toast } from '../../../utils/Toast';
import { File } from '../../../utils/File';
import { Formatters } from '../../../utils/Formatters';

//services
import { ContractService } from '../../../services/contract/ContractService';

//api
import { ApiException } from '../../../api/ApiException';

//moment
import moment from 'moment';

type Props = {
    id: string | undefined;
    contract: IContract | null;
};

const ContractDoc: React.FC<Props> = ({ id, contract }) => {
    const [data, setData] = useState<IGenericFileInfo>();
    const [loading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>('');
    const [showMessageError, setShowMessageError] = useState<boolean>(false);
    const [contractSimplified, setContractSimplified] = useState<IContractSimplified>();

    const downloadPDF = () => {
        if (data && data?.base64 && data?.fileName) {
            File.downloadPdf(data?.base64, data?.fileName);
        }
    }

    const sendEmail = async () => {
        setLoading(true);
        ContractService.sendEmailContract(contract?.contractCode || '').then((response) => {
            if (response instanceof ApiException) {
                Toast.error(response.message)
                setLoading(false)
            } else {
                Toast.success("Enviado com sucesso!");
                setLoading(false)
            }
        })
    }

    const getContractInfo = async () => {
        setLoading(true)
        ContractService.getContractSimplified(contract?.cpfCnpj || '', contract?.contractCode || '', contract?.dataOrigin || '').then((response) => {
            if (response instanceof ApiException) {
                setMessageError(response.message)
            } else {
                setContractSimplified(response);
            }
        })
    }
    const getDownloadContract = async () => {
        ContractService.getDownloadContract(contract?.contractCode || '').then((response) => {
            if (response instanceof ApiException) {
                setMessageError(response.message);
                setShowMessageError(response.showMessage);
            } else {
                setData(response);
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getContractInfo();
        getDownloadContract();
    }, [])

    return (
        <>
            <div className={styles.container}>
                {loading && <Loading />}
                <div className={styles.top}>
                    <h2 className={styles.tittle}>Contrato</h2>
                    <div className={styles.informations}>
                        <label className={styles.information}>
                            Contrato
                            <strong style={{ color: "#006930" }}>{contract?.contractCode}</strong>
                        </label>
                        <label className={styles.information}>
                            Valor Total da Operação
                            <strong>{contractSimplified?.totalValue && Formatters.formatMoneyInRealBrazil(contractSimplified?.totalValue)}</strong>
                        </label>
                        <label className={styles.information}>
                            Data da Operação
                            <strong>{contractSimplified?.beginDate && moment(contractSimplified?.beginDate).format("DD/MM/yyyy")}</strong>
                        </label>
                        <label className={styles.information}>
                            Número de Parcelas
                            <strong>{contractSimplified?.totalNumber}</strong>
                        </label>
                        <label className={styles.information}>
                            Valor da Parcela
                            <strong>{contractSimplified?.installmentValue && Formatters.formatMoneyInRealBrazil(contractSimplified?.installmentValue)}</strong>
                        </label>
                    </div>
                </div>
                <hr />
                <div className={styles.filter}>

                    {data && (
                        <div className={styles.actionButtons}>

                            {data.base64 && data.fileName && <button className={styles.download} onClick={downloadPDF}><FaFileDownload />Baixar</button>}
                            {sendEmail && <button className={styles.sendEmail} onClick={sendEmail}><MdOutlineMailOutline />Enviar para o E-mail</button>}
                        </div>

                    )}
                    {!loading && !data && (
                        <>
                            <Error message={messageError} showMessage={showMessageError} />
                        </>
                    )}
                </div>
                {data && (
                    <div>
                        <br />
                        <div style={{ width: "100%", paddingBottom: "56.25%", position: "relative" }}>
                            <ViewPdf
                                base={data.base64}
                                fileName={data.fileName}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default ContractDoc